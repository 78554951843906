/* ©2018 EdgeVerve Systems Limited (a fully owned Infosys subsidiary),
Bangalore, India. All Rights Reserved.
 */
window.corpBehaviours = window.corpBehaviours || {};
corpBehaviours.appMenuBehaviour = {
    properties: {
        userId: {
            type: String
        },
        corpId: {
            type: String
        }
    },
    fetchMenuProfileData: function() {
        var appDataElement = document.getElementById("page-title-label");
        return !_.isEmpty(appDataElement.appData.menuProfiles) ? appDataElement.appData.menuProfiles : appDataElement.appData;
    },
    // Writing below function so that the component can be used anywhere even without loading library function from lodash. 
    // Hence, can be used in pre-login flows ( before lodash is loaded ) too.
    _ifUndefined: function(objectArg) {
        if (typeof objectArg === "undefined")
            return true;
        return false;
    },
    validateActionOnButton: function(menuId, event) {
        var menuProfileData = this.fetchMenuProfileData();
        // Below condition is when user does not pass any menuId and does not want any validation, such as in cancel button or menuId passed is correct
        if (this._ifEmptyString(menuId) || (menuProfileData && menuProfileData.indexOf(menuId) >= 0)) {
            // stop propagation added so that user cannot put on-tap from the host component directly.
            // This will disable using on-tap from the host component
            event.stopPropagation();
            this.fire('tap-callback', event);
        } else {
            this.fire("oe-show-message", getLabel('l_PermissionErrorMessage'));
        }
    },
    validateElement: function(menuId) {
        var menuProfileData = this.fetchMenuProfileData();
        if (this._ifEmptyString(menuId) || (menuProfileData && menuProfileData.indexOf(menuId) >= 0)) {
            // console.log(false);
            return false;
        } else {
            // console.log(true);
            return true;
        }
    },
    validateActionOnUserAndMenu: function(menuId) {
        this._routeChanged();
        // Below condition is when user does not pass any menuId and does not want any validation, such as in cancel button
        var menuProfileData = this.fetchMenuProfileData();
        if (this._ifEmptyString(menuId) || (menuProfileData && menuProfileData.indexOf(menuId) >= 0)) {
            return true;
        } else {
            return false;
        }
    },
    validateDeepMenuAccess: function(menuKey) {
        if(menuKey){
        var deepMenuConfigData = menuConfig;
        var deepMenuConfigObj = deepMenuConfigData.find(function (item) {
            return menuKey == item.menukey;
        });
        if(deepMenuConfigObj){
            var menuProfileData = this.fetchMenuProfileData();
            if (this._ifEmptyString(deepMenuConfigObj.menuid) || (menuProfileData && menuProfileData.indexOf(deepMenuConfigObj.menuid) >= 0)) {
                return true;
            } else {
                return false;
            }
        }
        else{
            return false;
        }
    }
    return true;
    },
    _setUrl: function(menuId, url) {
        var message = "";
        //Added to fix same route navigation
        var mainBody = document.getElementById('mainbody');
        if (mainBody.finalRoute || mainBody.subroute) {
            if (mainBody.finalRoute.path == url || mainBody.subroute.path == url) {
                window.oe_navigate_to("/dummy", { "description": message });
            }
        }

        // Polymer3 upgrade - temporarily commenting the menu access check, as this feature is not available yet in Polymer3 branch.
        // TODO: this needs to be enabled as part of master merge.
        if (!this.validateActionOnUserAndMenu(menuId)) {
            url = "/error-msg";
            message = getLabel('l_AccessDeniedMessage');
        }
        window.oe_navigate_to(url, { "description": message });
    },
    _ifEmptyString: function(stringArg) {
        if (this._ifUndefined(stringArg) || stringArg === null || stringArg === '')
            return true;
        return false;
    } ,
    _valdateCOCDListMenuAccess: function(cocdList, cocdMenuMap){
        if(cocdMenuMap){
            for (var key in cocdMenuMap) {
                if(!this.validateDeepMenuAccess(key)){
                    cocdList = cocdList.filter(item => cocdMenuMap[key] !== item.cmCode);
                }
            }
            return cocdList;
        }
        return cocdList;
    },
    _validatemenus(mainmenu,submenu){
        if(this.validateDeepMenuAccess(mainmenu)){
            if(this.validateDeepMenuAccess(submenu)){
                return true;
            }
            else
            return false;
        }
        else return false;
    },
    _valdateTxnTypeListMenuAccess: function(responseList, txnTypeMenuMap){
        if(txnTypeMenuMap){
            for (var key in txnTypeMenuMap) {
                if(!this.validateDeepMenuAccess(key)){
                    responseList = responseList.filter(item => txnTypeMenuMap[key] !==  item.transactionType.cmCode);
                }
            }
            return responseList;
        }
        return responseList;
    }
};