/* ©2018 EdgeVerve Systems Limited (a fully owned Infosys subsidiary),
Bangalore, India. All Rights Reserved.
 */
import "./mdl-grid-css.js";

const LoginStyles = document.createElement("dom-module");

LoginStyles.innerHTML = `<template>
        <style include="mdl-grid-css iron-flex iron-flex-factors iron-flex-alignment">
            [hidden] {
                display: none!important;
            }

            /*Removed from mdl-grid.html*/
            /*the root container - no space on mobile except bottom margin, normal grod spacing on tab and desktop*/
            .mdl-grid.mdl-grid--root {
                padding: 8px;
            }

            .mdl-grid--root>.mdl-cell{
                margin: 8px
            }

            .spinner-innder-div{
                margin: 10%;
            }

            .mdl-grid.mdl-grid--center-justified {
                justify-content: center;
            }
            
            .mdl-cell--auto-width {
                box-sizing: border-box;
                width: auto;
            }
			app-dialog[name="h_Authorization"] {
                --dialog-background-color: {
                    background-color: var(--brand-color);
            };
                --dialog-text-color: {
                color: var(--background-primary);
              }
			}
            
            .mdl-right-no-spacing {
                padding-right: 0 !important;
                width: calc(100% - 8px) !important;
            }
                
            .mdl-right-no-spacing > .mdl-cell {
                margin-right: 0 !important;
                width: calc(100% - 8px) !important;
            }
            
            /*Removed from mdl-grid.html*/
            
            #index_HeaderPanel {
                --paper-scroll-header-panel-header-container: {
                    z-index: 2;
                }
                --paper-scroll-header-panel-container: {
                    -webkit-overflow-scrolling: auto;
                }
            }
            #index_HeaderPanel[phone],#index_HeaderPanel[tablet] {
             
                --paper-scroll-header-panel-container: {
                    -webkit-overflow-scrolling: touch;
                }
            }

            .critical-color-new {
                color: var(--critical-label-color);
            }
            .white-color{
                color: var(--card-light-background);
            }
            
            ::-webkit-scrollbar {
                width: 17px;
            }
            /* Customize Thumb */
            
            ::-webkit-scrollbar-thumb {
                background: var(--primary-dark-color);
                border-radius: 10px;
                border: 6px solid transparent;
                background-clip: content-box;
            }
            /* Customize Track */
            
            ::-webkit-scrollbar-track {
                border: 8px solid transparent;
                background: var(--divider-color);
                background-clip: content-box;
            }
            
            paper-tooltip {
                --paper-tooltip: {
                    font-size: var(--tooltip-font-size);
                }
                --paper-tooltip-opacity: 1;
                --paper-tooltip-text-color: var(--secondary-dark-color);
                --paper-tooltip-background: var(--primary-light-color);
                box-shadow: 0 2px 4px 0 var(--secondary-dark-color);
                margin-top:9px; 
            }
            /*Paper radio button, checkbox and Switch css*/
            
            paper-radio-button.primary-radio {
                font-size: var(--a-font-size-s);
                --paper-radio-button-size: 22px;
                --paper-radio-button-unchecked-color: var(--divider-color);
                --paper-radio-button-checked-color: var(--brand-color);
                --paper-radio-button-label-color: var(--secondary-dark-color);
            }
            
            paper-checkbox.primary-checkbox {
                --paper-checkbox-unchecked-color: var(--divider-color);
                --paper-checkbox-checked-color: var(--brand-color);
                --paper-checkbox-checkmark-color: var(--primary-light-color);
                --paper-checkbox-checked-ink-color: var(--brand-color);
                --paper-checkbox-label-color: var(--secondary-dark-color);
            }
            
            paper-checkbox.checkbox-light-style[desktop] {
                --paper-checkbox-unchecked-color: var(--primary-light-color);
                --paper-checkbox-checked-color: var(--primary-light-color);
                --paper-checkbox-checkmark-color: var(--brand-color);
                --paper-checkbox-checked-ink-color: var(--primary-light-color);
                --paper-checkbox-unchecked-color: var(--primary-light-color);
            }
            
           
            oe-input,
            oe-date oe-input,
            oe-date,
            gold-email-input,
            oe-decimal, oe-textarea, oe-combo, gold-phone-input {
                --paper-input-container-underline: {
                    border-bottom-color: var(--ripple-color);
                }
                --paper-input-container-label: {
                    color: var(--secondary-dark-color);
                }
                font-family: 'Roboto-Regular', 'Noto', sans-serif;
                --paper-input-container-input-color: var(--primary-dark-color);
                --paper-input-container-invalid-color:var(--secondary-color-but);
                --paper-input-container-input: {
                    text-align: initial;
                    line-height: 26px;
                }
                --paper-input-container-label-focus: {
                    color: var(--secondary-dark-color);
                }
                --paper-input-error: {
                    margin-top: 2px;
                }
                /*--paper-input-container-underline-focus: {
                    display: none;
                }*/
                
                --paper-dropdown-menu-icon: {
                    color: var(--secondary-dark-color);
                }
            
            }

            /*To remove required start asterisk*/
            oe-input, oe-combo, oe-date, oe-decimal, oe-radio-group {
                --oe-required-mixin: {
                    display:none;
            }
            }

            /* code to remove asterisk symbol from required inputs */

			/* To control the height and spacing between records of oe-combo */
			oe-combo {
				--paper-item-min-height: 35px;
				--paper-material:{
					max-height: 300px !important;
				}
				--paper-item-focused: {
					background-color: #ccc;
				}
			}
        
            
            oe-decimal {                
                --paper-input-container-input:{
                    font-size: var(--a-font-size-l);
                    line-height: 26px;
                }
                /*added from 1109 */
                --paper-input-container-underline-focus: {
                    display: none;
                }
                /*added from 1109 */
            }

            oe-input.text-align-right {
                --paper-input-container-label: {
                    text-align: right;
                }
                --paper-input-container-input: {
                    text-align: right;
                }
            }
            
            oe-input.no-border {
                --paper-input-container-underline: {
                    border-bottom: none;
                }
            }

            /*Drop down text box mixin*/
            /*Sticky content CSS*/
            
            .display-none {
                display: none;
            }

            oe-info.primary-dark-color {
                --oe-info-label: {
                    color: var(--primary-dark-color);
                }
                --oe-info-value: {
                    color: var(--primary-dark-color);
                }
            }

            oe-info.secondary-dark-color-s {
                --oe-info-label: {
                    font-size: var(--a-font-size-s);
                    color: var(--secondary-dark-color);
                }
                --oe-info-value: {
                    font-size: var(--a-font-size-s);
                    color: var(--secondary-dark-color);
                }
            }

            oe-info.secondary-light-color {
                --oe-info-label: {
                    color: var(--secondary-light-color);
                }
                --oe-info-value: {
                    color: var(--secondary-light-color);
                }
            }

            oe-info {
                font-size: inherit;
                --oe-info-label: {
                    padding: 0;
                    font-size: var(--a-font-size-m);
                    color: var(--primary-dark-color);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                --oe-info-value: {
                    padding: 0;
                    font-size: var(--a-font-size-s);
                    color: var(--disabled-dark-color);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            
            oe-info.display-date {
                --oe-info-value: {
                    font-size: var(--a-font-size-m);
                }
            }
            
            
            div.banner-skew {
                position: absolute;
                top: 6.6vw;
                width: 100%;
                right: 0;
                min-height: 440px;
                background: var(--brand-color);
                transform: skewy(8deg);
                overflow:hidden;
            }
            
            div.banner-skew:before {
                content: "";
                position: absolute;
                width: 100%;
                top:7vw;
                min-height:440px;
                transform: skewy(-8deg);
                background-image: var( --dashboard_bg_texture);
                background-repeat: no-repeat;
                filter: brightness(1.5);
                background-size: 200%;
                background-position: bottom;
            }

            /*Registration/onboarding skewed banner*/
            
            .page-left-image {
                background: var( --background-image);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: bottom;
            }
            
            paper-card,
            oe-input,
            sb-phone,
            oe-date,
            gold-cc-input,
            gold-email-input {
                width: 100%;
            }
            /*Paper card background light*/
            
            paper-card {
                background: var(--primary-light-color);
            }
            /*Paper button background light*/
            paper-button {
                --paper-button: {
                    font-size: var(--a-button-font-size);
                }
            }
            
            paper-button i18n-msg {
                color: var(--flat-button-color);
                font-family: var(--medium-font);
            }
            
            
            paper-button i18n-msg {
                color: var(--flat-button-color);
                font-family: var(--medium-font);
            }
            paper-button : focus{
                opacity :0.5;
            }
            
            paper-toolbar.header-style {
                --paper-toolbar-background: var(--primary-light-color);
                --paper-toolbar-color: var(--primary-dark-color);
                --paper-toolbar: {
                    box-shadow: 0px 0px 0px;
                }
            }
            
            paper-toolbar {
                --paper-toolbar-background: var(--primary-light-color);
                --paper-toolbar-color: var(--primary-dark-color);
                --paper-toolbar: {
                    box-shadow: 0px 0px 0px;
                }
                ;
                --paper-toolbar-content: {
                    padding: 0px !important;
                }
            }

            paper-toolbar[phone] {
                padding-top: 26px !important;
                height: 76px !important;
            }
            
            paper-item {
                --paper-item: {
                    font-size: unset;
                }
            }
            
            paper-item {
                --paper-item-focused-before: {
                    background: transparent;
                }
                
            }

            /*Phone number having Country code*/
            paper-icon-item {
                --paper-item-focused-before: {
                    background: transparent;
                }
            }

            paper-card.round-icon-list-wrapper {
                box-shadow: var(--shadow-elevation-1);
                border-radius: 6px;
            }
            
            paper-icon-item.round-content-list {
                --paper-item-icon-width: 80px;
            }
            /*to fix the footer at bottom having button*/
            
            .fixed-footer {
                position: absolute;
                bottom: calc(0vh - -16px);
                left: 0;
                right: 0;
            }
            
            
            .fixed-footer-container {
                padding-bottom: 56px;
            }
            
            .display-flex-wrap {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                flex-wrap: wrap;
            }
            
            .content-wrapper[phone] {
                min-height: calc(100vh - 56px);
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                flex-wrap: wrap;
            }

            .container {
                min-height: calc(100vh - 56px);
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                flex-wrap: wrap;
            }

            .container[desktop] {
                min-height: calc(100vh - 136px);                
            }

            .justify-content-space-bwn {
                -webkit-justify-content: space-between;
                justify-content: space-between;
            }

            .page-container-min-height {
                min-height: calc(100vh - 64px);
            }
            
            .page-container-min-height[phone] {
                min-height: calc(100vh - 56px);
            }
            /*flex align items end*/
            
            .align-end {
                align-self: flex-end;
            }
            /*flex align items start*/
            
            .align-start {
                align-self: flex-start;
                align-items: start;
            }
            /*to fix the footer at bottom having button*/
            
            html,
            body {
                height: 100%;
                margin: 0;
            }
            
            paper-scroll-header-panel {
                height: 100% !important;
            }
            /*.header-style {
                background: var(--primary-light-color);
                box-shadow: none;
            }*/
            
            .back-button paper-icon-button {
                padding: 0;
                width: 24px;
                height: 24px;
            }
            
            .page-header-text-wrapper {
                font-size: var(--a-font-size-m);
                letter-spacing: -0.41px;
                font-weight: var(--font-weight-bold);
                text-align: center;
                padding: 4px 0 0 0;
            }
            
            .banner-icon-wrapper {
                background: var(--background-secondary);
                width: 154px;
                height: 154px;
                border-radius: 50%;
                margin: 10vh auto;
            }
            
            .banner-icon-text {
                color: var(--primary-dark-color);
                font-size: var(--a-font-size-m);
                width: 42%;
                margin-top: -20px;
            }
            
            .unfocused-line.paper-input-container {
                background-color: var(--divider-color);
            }
            
            .banner-image-verify {
                width: 154px;
                height: 154px;
                margin: 4vh auto;
            }

            .page-left-image-direct-banking {
                background: var(--background_Image_sd);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: bottom;
            }   

            .mainpage-ev-login-admin {
                background-image: var(--login_background);
                height: 100%;
                width: 100%;
            }

            .image-div-profile-details{
				background-size:100% 100%;
				background-image: var(--dashboard_bg);
			}
            
            .image-div-corporate-profile-main {
                background-size: 100% 100%;
                background-image: var(--dashboard_bg);
            }

            .iron-icon-style-m {
                width: 32px;
                height: 32px;
                min-width: 32px;
            }
            
            .iron-icon-style-l {
                width: 42px;
                height: 42px;
                min-width: 42px;
            }
            
            .iron-icon-style-xxl {
                width: 72px;
                height: 72px;
                min-width: 72px;
            }

            .iron-icon-style-xl {
                width: 52px;
                height: 52px;
                min-width: 52px;
            }
            
            .iron-icon-style-s {
                width: 22px;
                height: 22px;
                min-width: 22px;
            }
			.iron-icon-color{
				color: var(--brand-color) !important;
			}
			iron-icon.circle-unchecked{
				color: var(--divider-color);
			}
			iron-icon.circle-checked{
				color: var(--brand-color);
			}
            
            paper-icon-button.paper-icon-button-no-ink {
            --paper-icon-button-ink-color:transparent;
            }

            /*frequently used styles*/
            /*white Background CSS*/
            
            .light-background {
                background: var(--primary-light-color);
            }
            /*Brand Background CSS*/
            
            .brand-background-color {
                background: var(--brand-color);
            }
            
            .secondary-brand-background {
                background: var(--secondary-brand-color);
            }
            /*Gray background CSS*/
            
            .background-primary {
                background: var(--background-primary);
            }
            
            .background-secondary {
                background: var(--background-secondary);
            }
            
            .background-transparent[desktop] {
                background: transparent;
            }
            
            .secondary-dark-background {
                background: var(--secondary-dark-color);
            }
            
            .row-flex-container {
                -ms-box-orient: horizontal;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -moz-flex;
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: space-around;
                justify-content: space-around;
                -webkit-flex-flow: row wrap;
                flex-flow: row wrap;
                -webkit-align-items: stretch;
                align-items: stretch;
            }
            
            .content-align-center {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            
            .content-align-vertical-center {
                display: flex;
                align-items: center;
            }
            
            .content-align-horizontal-end {
                display: flex;
                justify-content: flex-end;
            }
            
            .column-flex-container {
                -ms-box-orient: vertical;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -moz-flex;
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: space-around;
                justify-content: space-around;
                -webkit-flex-flow: column wrap;
                flex-flow: column wrap;
                -webkit-align-items: stretch;
                align-items: stretch;
            }
            /*Align text*/
            
            .text-align-right {
                text-align: right;
            }
            
            .text-align-left {
                text-align: left;
        
            }
            
            .text-align-center {
                text-align: center;
            }
            
			.text-align-justify {
				text-align: justify;
			}            
            
            .text-transform-uppercase {
                text-transform: uppercase;
            }
            
            .text-transform-lowercase {
                text-transform: lowercase;
            }
            
            .text-transform-capitalize {
                text-transform: capitalize;
            }
            
            .text-overflow-ellipsis {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            
            .full-width {
                width: 100% !important;
            }
            
            .full-height {
                height: 100%;
            }
            
            .full-height-desktop[desktop] {
                min-height: 100vh;
            }
            
            .height-fill-available {
                height: -webkit-fill-available;
            }
            
            .height-auto  {
                height: auto;
            }
            
            .min-height-auto {
                min-height: auto;
            }
            
            .half-width {
                width: 50%;
            }
            
            .margin-zero {
                margin: 0 !important;
            }
            
            :dir(ltr) .margin-r-zero {
                margin-right: 0 !important;
            }
            
            
            .margin-t-zero {
                margin-top: 0 !important;
            }
            
            .margin-b-zero {
                margin-bottom: 0 !important;
            }
			 .margin-l-zero {
                margin-left: 0 !important;
            }
            
            .padding-zero {
                padding: 0 !important;
            }
            
            .padding-zero-phone[phone] {
                padding: 0;
            }
            
            .padding-b-zero {
                padding-bottom: 0 !important;
            }
            
            .padding-lr-zero {
                padding-right: 0 !important;
                padding-left: 0 !important;
            }
            

            .padding-t-zero {
                padding-top: 1px !important;
            }
            
            .font-size-inherit {
                font-size: inherit !important;
            }
            
            .font-size-xxs {
                font-size: var(--a-font-size-xxs) !important;
            }
            
            .font-size-xs {
                font-size: var(--a-font-size-xs) !important;
            }
            
            .font-size-s {
                font-size: var(--a-font-size-s) !important;
            }
            
            .font-size-m {
                font-size: var(--a-font-size-m) !important;
            }
            
            .font-size-l {
                font-size: var(--a-font-size-l) !important;
            }
            
            .font-size-xl {
                font-size: var(--a-font-size-xl) !important;
            }
            
            .font-size-xxl {
                font-size: var(--a-font-size-xxl) !important;
            }
            /*Roboto Font families*/
            
            .font-weight-light {
                font-family: var(--light-font) !important;
            }
            
            .font-weight-bold {
                font-family: var(--bold-font) !important;
            }
            
            .font-regular {
                font-family: var(--regular-font) !important;
            }
            
            .font-medium {
                font-family: var(--medium-font) !important;
            }
            
            .font-thin {
                font-family: var(--thin-font) !important;
            }
            
            .font-italic {
                font-style: italic !important;
            }
            /*Roboto Font families*/
            
            .overflow-hidden {
                overflow: hidden;
            }
            
            .overflow-x-hidden {
                overflow-x: hidden;
            }
            
            .overflow-y-hidden {
                overflow-y: hidden;
            }
            
            .overflow-visible {
                overflow: visible;
            }
            
            .line-height-medium {
                line-height: 18px;
            }
            
            .line-height-nomal {
                line-height: normal;
            }
            /*Border style */
            
            .border-1px {
                border: 1px solid var(--divider-color);
            }
            
            .border-b-1px {
                border-bottom: 1px solid var(--divider-color);
            }
            
            .border-t-1px {
                border-top: 1px solid var(--divider-color);
            }
            
            .border-l-1px {
                border-left: 1px solid var(--divider-color);
            }
            
            .border-r-1px {
                border-right: 1px solid var(--divider-color);
            }
            
            
            .border-light-1px {
                border: 1px solid var(--primary-light-color);
            }
            
            .border-round-8px {
                border-radius: 8px;
            }
            
            .border-round-16px {
                border-radius: 16px;
            }
            
            .border-round-24px {
                border-radius: 24px;
            }
            
			.border-round-tl-tr-24px {
                border-radius: 24px 24px 0 0;
            }
            
			.border-round-bl-br-24px {
                border-radius: 0 0 24px 24px;
            }            
            
            .border-round-4px {
                border-radius: 4px;
            }
            
            .border-radius-tl-tr-12px {
                border-radius: 12px 12px 0 0;
            }
            
            .border-radius-bl-br-12px {
                border-radius: 0 0 12px 12px;
            }
            
            .border-radius-0 {
                border-radius: 0;
            }
            
            .border-b-dotted {
                border-bottom: 2px dotted var(--divider-color);
            }
            
            .border-b-dashed {
                border-bottom: 2px dashed var(--divider-color);
            }
            
            .active-border-l {
                border-left: 4px solid var(--brand-color);
            }
            
            .active-border-l-8px {
                border-left: 8px solid var(--brand-color);
            }
            
            
            
            .text-brand-color {
                color: var(--brand-color) !important;
            }
            /*Banner brand color*/
            
            .text-decoration-none {
                text-decoration: none;
            }
            
            .banner-brand {
                background: var(--brand-color);
                min-height: 140px;
                background-image: var( --dashboard_bg_texture)
                background-repeat-y: no-repeat;
                background-position: center;
            }
            
            .light-banner {
                background: var(--primary-light-color);
                min-height: 240px;
            }
            /*Style for text in primary-light-color*/
            
            .primary-light-color {
                color: var(--primary-light-color);
            }
            /*Title Style*/
            
            .title-dark {
                color: var(--primary-dark-color);
                font-family: var(--medium-font);
                font-size: var(--a-font-size-l);
            }
            
            .title-light {
                color: var(--primary-light-color);
                font-family: var(--medium-font);
                font-size: var(--a-font-size-l);
            }
            /*Header Style*/
            
            .header-dark {
                color: var(--primary-dark-color);
                font-family: var(--medium-font);
                font-size: var(--a-font-size-m);
                padding: 16px 16px 0 16px;
                display: flex;
            }
            
            .header-light {
                color: var(--primary-light-color);
                font-family: var(--medium-font);
                font-size: var(--a-font-size-m);
                padding: 16px 16px 0 16px;
            }

            /*Primary text css*/
            
            .primary-dark-text-xs {
                font-size: var(--a-font-size-xs);
                color: var(--primary-dark-color);
            }
            
            .primary-dark-text-s {
                font-size: var(--a-font-size-s);
                color: var(--primary-dark-color);
            }
            
            .primary-dark-text-m {
                font-size: var(--a-font-size-m);
                color: var(--primary-dark-color);
            }
            
            .primary-dark-text-l {
                font-size: var(--a-font-size-l);
                color: var(--primary-dark-color);
            }
            
            .primary-dark-text-xl {
                font-size: var(--a-font-size-xl);
                color: var(--primary-dark-color);
            }
            
            .primary-dark-text-xxl {
                font-size: var(--a-font-size-xxl);
                color: var(--primary-dark-color);
                font-family: var(--thin-font);
            }
            
            .primary-dark-text-xxxl {
                font-size: var(--a-font-size-xxxl);
                color: var(--primary-dark-color);
            }
            
            .primary-light-text-xs {
                font-size: var(--a-font-size-xs);
                color: var(--primary-light-color);
            }
            
            .primary-light-text-desktop[desktop] {
                color: var(--primary-light-color);
            }
            
            .primary-light-text-s {
                font-size: var(--a-font-size-s);
                color: var(--primary-light-color);
            }
            
            .primary-light-text-m {
                font-size: var(--a-font-size-m);
                color: var(--primary-light-color);
            }
            
            .primary-light-text-l {
                font-size: var(--a-font-size-l);
                color: var(--primary-light-color);
            }
            
            .primary-light-text-xl {
                font-size: var(--a-font-size-xl);
                color: var(--primary-light-color);
                font-family: var(--light-font);
            }
            
            .primary-light-text-xxl {
                font-size: var(--a-font-size-xxl);
                color: var(--primary-light-color);
            }
            
            .primary-light-text-xxxl {
                font-size: var(--a-font-size-xxxl);
                color: var(--primary-light-color);
                font-family: var(--thin-font);
            }
            /*secondary text css*/
            
            .secondary-dark-text-xs {
                font-size: var(--a-font-size-xs);
                color: var(--secondary-dark-color);
            }
            
            .secondary-dark-text-s {
                font-size: var(--a-font-size-s);
                color: var(--secondary-dark-color);
            }
            
            .secondary-dark-text-m {
                font-size: var(--a-font-size-m);
                color: var(--secondary-dark-color);
            }
            
            .secondary-dark-text-l {
                font-size: var(--a-font-size-l);
                color: var(--secondary-dark-color);
            }
            
            .secondary-dark-text-xl {
                font-size: var(--a-font-size-xl);
                color: var(--secondary-dark-color);
            }
            
            .secondary-dark-text-xxl {
                font-size: var(--a-font-size-xxl);
                color: var(--secondary-dark-color);
            }
            
            .secondary-dark-text-xxxl {
                font-size: var(--a-font-size-xxxl);
                color: var(--secondary-dark-color);
            }
            
            .secondary-light-text-xs {
                font-size: var(--a-font-size-xs);
                color: var(--secondary-light-color);
            }
            
            .secondary-light-text-s {
                font-size: var(--a-font-size-s);
                color: var(--secondary-light-color);
            }
            
            .secondary-light-text-m {
                font-size: var(--a-font-size-m);
                color: var(--secondary-light-color);
            }
            
            .secondary-light-text-l {
                font-size: var(--a-font-size-l);
                color: var(--secondary-light-color);
            }
            
            .secondary-light-text-xl {
                font-size: var(--a-font-size-xl);
                color: var(--secondary-light-color);
            }
            
            .secondary-light-text-xxl {
                font-size: var(--a-font-size-xxl);
                color: var(--secondary-light-color);
            }
            
            .secondary-light-text-xxxl {
                font-size: var(--a-font-size-xxxl);
                color: var(--secondary-light-color);
            }
            /* outstanding text CSS */
            
            .outstanding-text-xs {
                font-size: var(--a-font-size-xs);
                color: var(--outstanding-color);
            }
            
            .outstanding-text-s {
                font-size: var(--a-font-size-s);
                color: var(--outstanding-color);
            }
            
            .outstanding-text-m {
                font-size: var(--a-font-size-m);
                color: var(--outstanding-color);
            }
            
            .outstanding-text-l {
                font-size: var(--a-font-size-l);
                color: var(--outstanding-color);
            }
            
            .outstanding-text-xl {
                font-size: var(--a-font-size-xl);
                color: var(--outstanding-color);
            }
            
            .outstanding-text-xxl {
                font-size: var(--a-font-size-xxl);
                color: var(--outstanding-color);
            }
            
            .outstanding-text-xxxl {
                font-size: var(--a-font-size-xxxl);
                color: var(--outstanding-color);
            }
            /* outstanding text CSS */
            /* position related styling */
            
            .position-relative {
                position: relative;
            }
            
            .position-absolute {
                position: absolute;
            }

            .position-absolute-cover {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
            }
            
            .position-submerged {
                position: fixed;
                position: -webkit-sticky;
                bottom: -2%;
                right: 0%;
                left: 0%;
            }
            
            /*visiblity related class */
            
            .display {
                display: none;
            }
            
            .display-block {
                display: block;
            }
            
            .display-flex {
                display: flex;
            }
            
            .display-inline {
                display: inline;
            }
            /*Disabled black color*/
            
            .disabled-dark-color {
                color: var(--disabled-dark-color);
            }
            /*Disabled white color*/
            
            .disabled-light-color {
                color: var(--disabled-light-color);
            }
            /*Notification text color*/
            
            .warning-color {
                color: var(--warning-label-color);
            }
            
            .critical-color {
            /* changes from 1109*/
                /* color: var(--critical-label-color); */
				color: var(--primary-light-color);
				
            }
            
            .success-color {
                color: var(--success-label-color);
            }
            
            .info-color {
                color: var(--info-label-color);
            }
            
            .outstanding-color {
                color: var(--outstanding-color);
            }
            
            .credited-text-color {
                color: var(--chart-circle-color-light-1);
            }
            /*Notification text color*/
            /*Special phrase text*/
            
            .special-light-phrase-text-xs {
                color: var(--primary-light-color);
                font-size: var(--a-font-size-xl);
                font-family: var(--light-font);
            }
            /*Special phrase text Amount*/
            
            .special-dark-Amount-text-xs {
                color: var(--primary-dark-color);
                font-size: var(--a-font-size-xl);
                font-family: var(--medium-font);
            }
            
            .special-colored-Amount-text-xs {
                color: var(--critical-label-color);
                font-size: var(--a-font-size-xl);
                font-family: var(--medium-font);
            }
            /*input Validation Messages style*/
            
            .input-validation-message {
                font-size: var(--a-font-size-xs);
                color: var(--critical-label-color);
            }
            /*Success-failure text style*/
            
            .success-failure-text {
                font-size: var(--a-font-size-xxl);
                color: var(--primary-dark-color);
                font-family: var(--light-font);
            }
            /*Horizontal Scroll content*/
            
            .horizontal-scroll {
                overflow-x: auto;
                white-space: nowrap;
            }
            
            .horizontal-scroll[phone]::-webkit-scrollbar {
                height: 0px;
                background: transparent;
            }
            
            .horizontal-scroll[tablet]::-webkit-scrollbar {
                height: 0px;
                background: transparent;
            }
            
            
            .brand-icon {
                --iron-icon-width: 48px;
                --iron-icon-height: 48px;
                --iron-icon-fill-color: var(--brand-color);
            }
            
            
            .dynamic-Container[extra-wide-layout] {
                padding-top: 0;
                background-color: var(--primary-light-color);
            }
            
            .dynamic-Container[wide-layout] {
                padding-top: 0;
                background-color: var(--primary-light-color);
            }
            
            .dynamic-Container {
                background-color: var(--background-primary);
            }
            
            .page-container[extra-wide-layout],
            .page-container[wide-layout] {
                background-color: var(--background-primary);
                border-radius: 2px;
                padding: 16px 16px 0px 16px;
            }
            
            .info-container {
                display: inline;
                padding: 3px 12px 3px 3px;
            }
            /* not reviwed */
            
            .boxContent {
                padding-left: 48px;
                border-radius: 5px 5px 0 0;
            }
            
            
            .boxContainer {
                display: inline-block;
                background-color: var(--primary-light-color);
            }
        
            
            .container-left-padding {
                padding-left: 8px;
            }
            
            .more-menu,
            .position-r-t {
                position: absolute;
                right: 8px;
                top: 8px;
            }
            
            .close-button {
                position: absolute;
                right: 0;
                z-index: 1;
            }
            

            .menu {
                position: absolute;
                top: 0;
            }
            
            .padding-r-l {
                padding-right: 32px;
                padding-left: 32px;
            }
            
            /* not reviwed */
            /* Upcoming Bills Page*/
            /*Webcomponent template CSS*/
            /*frequently used styles*/
            
            .position-absolute-t-r {
                position: absolute;
                top: 0;
                right: 0;
            }
            
            
            .boxHeader {
                color: var(--primary-light-color);
            }

            
            .flex-1 {
                flex: 1;
            }
            
            .chart-circle-color-light-1, .random-light-text-1 {
                color: var(--chart-circle-color-light-1) !important
            }

            .random-light-text-2 {
                color: var(--chart-circle-color-light-2);
            }

            .random-light-text-3 {
                color: var(--chart-circle-color-light-3);
            }

            .random-light-text-4 {
                color: var(--chart-circle-color-light-4);
            }

            .random-light-text-5 {
                color: var(--chart-circle-color-light-5);
            }

            .random-light-text-6 {
                color: var(--chart-circle-color-light-6);
            }

            .random-light-text-7 {
                color: var(--chart-circle-color-light-7);
            }

            .background-light-1, .background-light-1.card-background {
                background: var(--chart-circle-color-light-1) !important
            }

            .background-light-2, .background-light-2.card-background {
                background: var(--chart-circle-color-light-2) !important
            }

            .background-light-3, .background-light-3.card-background {
                background: var(--chart-circle-color-light-3) !important
            }

            .background-light-4, .background-light-4.card-background {
                background: var(--chart-circle-color-light-4) !important
            }

            .background-light-5, .background-light-5.card-background {
                background: var(--chart-circle-color-light-5) !important
            }

            .background-light-6, .background-light-6.card-background {
                background: var(--chart-circle-color-light-6) !important
            }

            .background-light-7, .background-light-7.card-background {
                background: var(--chart-circle-color-light-7) !important
            }
            
            .iron-icon-style-xs {
                width: 18px;
                height: 18px
            }
            
            .half-width-desktop[desktop] {
                width: 50%;
            }
            
            .icon-border-light {
                border-radius: 50%;
                background-color: var(--primary-light-color);
            }
            
            .fab-button-wrapper {
                width: 92px;
            }
            
            .cursor-pointer {
                cursor: pointer;
            }
            /*Used in Onboarding profile pic*/
            
            app-fab.icon-cover-app-fab {
                --app-fab-icon-style: {
                    height: 100%;
                    width: 100%;
                    position: absolute;
                }
            }
            /*Used in Onboarding profile pic*/
            
            /*Accourdian arrow icon display effect on hover*/
            .mainContainer:hover .data-row,
            .mainContainer:focus .data-row,
            .action-button-focused .data-row {
                background-color: var(--background-primary);
            }

            .mainContainer.iron-selected:hover .data-row, 
            .mainContainer.iron-selected:focus .data-row {
                background-color: inherit;
            }
            
            
            .mainContainer:hover .icon-visible, .mainContainer:hover .list-action-button, .mainContainer.iron-selected:hover .hide-value, .mainContainer.iron-selected .hide-value,
            .mainContainer:focus .icon-visible, .mainContainer:focus .list-action-button, .mainContainer.iron-selected:focus .hide-value, .mainContainer.iron-selected .hide-value,
            .action-button-focused .icon-visible, .action-button-focused .list-action-button, .action-button-focused .hide-value {
                display: flex !important;
            }

            .mainContainer:hover .icon-visible[selected], .mainContainer:hover .hide-value, .mainContainer.iron-selected:hover .list-action-button, .mainContainer.iron-selected .list-action-button,
            .mainContainer:focus .icon-visible[selected], .mainContainer:focus .hide-value, .mainContainer.iron-selected:focus .list-action-button, .mainContainer.iron-selected .list-action-button {
                display: none !important;
            }

            .mainContainer:hover .icon-disable[selected],
            .mainContainer:focus .icon-disable[selected] {
                display: none !important;
            }
            /*Accourdian arrow icon display effect on hover*/

            
            .item-selected {
                background: var(--brand-color);
                color: var(--primary-light-color);
                font-size: var(--a-font-size-m);
                border-radius: 12px;
            }
            
            .item-unselected {
                color: var(--secondary-dark-color);
                font-size: var(--a-font-size-m);
                border-radius: 12px;
                border: 1px solid var(--divider-color);
            }
            
            .overdue {
                background-color: var(--primary-color-but);
                padding: 2px 8px;
            }
            
            .searchBox {
                //border: 1px solid var(--divider-color); BY Satya
                border-radius: 48px;
                //height: 42px; BY Satya
				height: 40px;
                width: 250px;
                padding-left: 8px;
            }
            
            /* MicroInteraction oe-input */
           .newInput {
            max-width:25%;
            --paper-input-container-label_-_color: var(--field-unchecked-color);
            --paper-input-container-color:var(--field-unchecked-color);
        }

            .searchBox oe-input {
                --paper-input-container: {
                    padding-bottom: 0;
                    height: 1px;
                };
                --paper-input-container-underline: {
                    display: none;
                    border-bottom-color: var(--primary-light-color);
                };
                --paper-input-container-underline-focus: {
                    display: none;
                };
            }

            paper-item {
                --paper-item: {
                    font-size: inherit;
                }
                --paper-item-body: {
                    font-size: inherit;
                }
            }

            
            .banner-background-primary {
                background-size: 100% 100%;
                background-image: var(--dashboard_bg);
                background-repeat: no-repeat;
            }

            .image-div-dashboard{
				background-size:100% 100%;
				min-height:212px;
				background-image: var(--dashboard_bg);
			}

			.image-div-dashboard[desktop]{
				min-height:100px;
			}
            
            .hybrid-screens .banner-background-primary {
                background-position: bottom;
                background-size: 100% 45%;
            }
            /*Disable copy from application*/
            
            /*Disable copy from application*/
        
            .white-space-initial {              /*Break line into multiple lines*/
                white-space: initial;
            }
            
            
            .ie-display {
                display: none !important;
            }

            /*Css hack for IE*/
            
            
            
            .drop-down-options {
                right: 0;
                top: 40px;
                box-shadow: 0 0 2px 0 var(--tabs-box-shadow-first);
                z-index: 1;
            }

            .card-close-button {
                padding: 10px;
            }

            .drop-down-list-container {
                max-height: 151px;
                overflow: auto;
            }            
            
            .loan-card-more-option{
                background-color: var(--primary-light-color);
                margin-top: -8px;
                margin-bottom: -8px;
                margin-right: -8px;
                border-bottom-right-radius: 8px;
                border-top-right-radius: 8px;
            } 
            
            .chips-option-unSelected.chipTab{
                font-size: var(--a-font-size-s);
                font-family: var(--regular-font);                    
                border-radius: 17px;
                background-position: center;
                transition: background 0.5s;
            }
            .chips-option-unSelected.chipTab:hover {
                background: var(--background-secondary) radial-gradient(circle, transparent 1%, var(--background-secondary) 1%) center/15000%;
                opacity:0.9;
            }
            .chips-option-unSelected.chipTab:active {
                background-color:var(--ripple-color);
                background-size: 100%;
                transition: background 0s; 
            }
            /* Below styles need to place is required file - when found solution for shadow dom styling - end*/
            /*Styles for accounts list starts here*/
            .account-id, .available-balance-text {
                position:relative;
                top:4.3px;
            }
            /*Styles for accounts list ends here*/ 

            .footer-menu-overlay {
                z-index: 3;
                background-color: var(--disabled-dark-color);
                position: fixed;
                width: 100%;
                bottom: 0;
                top: 0;
                opacity: 0;
                animation: .3s ease 0s normal forwards 1 animateOpacity;
            }            

            @keyframes animateOpacity {
                0%   { opacity: 0; }
                100% { opacity: 1; }
              }

            .outlineOffset:focus{
                outline: 2px auto var(--DDA-outline-color);
                outline-offset: 2px !important;
            }
			/* paper tabs text Color changed 31-03-2020*/
			paper-tabs paper-tab.iron-selected {
                color: #00839B!important;
            }
            paper-tabs paper-tab.iron-selected:hover{
                color: #00839B!important;
            }
			/* paper tabs text Color changed 31-03-2020*/
             .custom-paper-tab-blue {
               
                --paper-tabs-selection-bar-color: transparent; 
                box-shadow: 0 4px 15px 0 var(--tabs-box-shadow-first), 0 6px 4px 0 var(--tabs-box-shadow-second);
                transition: all 0.4s;
                max-width: 200px;
            }
            
             .custom-paper-tab-blue:hover{
                color: var(--brand-color)                    
            }
            paper-tabs  .custom-paper-tab-blue.iron-selected {
                color: var(--brand-color)              
            }
			.align-self-center {
                align-self: center;
            }	
			paper-icon-button#chipClose #icon{
                width:20px;
                height:20px;
            }
			.content-align-vertical-start {
                display: flex;
                align-items: flex-start
            }
			/* Text brand color changed 31-03-2020*/
			.text-brand-color {
			color: #003d4c !important;
            }

            div, h1, h2, h3, h4, h5, h4, span, p, label {
                font-family: SF Pro Display;
            }

            app-dialog.header-brand-color {
                --dialog-background-color: {
                                background-color: var(--brand-color);
                }
                --dialog-text-color: {
                                color: var(--background-primary);
                }
            }
			
			paper-tabs {
                --paper-tabs-selection-bar-color: #00839b !important;
            }
			paper-button.secondary-button {
                /*Secondary button class*/
                color: #003d4c !important;
			}
			app-dialog {
				--dialog-background-color_-_background-color: var(--brand-color) !important;
			}
			div.banner-skew {
				top: 6.6vw;
			}

            /* 08-04-2020 */
			
			.font_familySFPro{
                font-family:SF Pro Display;
				
            }
			.heading-3{
				font-size:32px;
			}
			.font-24{
				font-size:24px;
			}
			.font-20{
				font-size:20px;
			}
			.font-18{
				font-size:18px;
			}
			.font-16{
				font-size:16px;
			}
			.font-14{
				font-size:14px;
			}
			.font-13{
				font-size:13px;
			}
			.font-10{
				font-size:10px;
			}
            .font-11{
				font-size:11px;
			}
			.font-weight-500{
				font-weight:500;
			}
			
			.font-weight-600{
				font-weight:600;
			}
			.secondary-color{
				color: var(--secondary-color) !important;
			}
			.font-34{
				font-size:34px;
			}
			.font-weight-700{
				font-weight:700;
			}
			.font-weight-400{
				font-weight:400;
			}
			.font-30{
				 font-size: var(--a-font-size-30) !important;
			}
			.margin-30{
				margin:30px;
			}
            .margin-32{
				margin:32px;
			}
            .font-12{
                font-size:12px;
            }
            .font-15{
                font-size:15px;
            }
            
			oe-input,
            oe-date oe-input,
            oe-combo{
                --paper-input-container-label: {
                    color: black;
                }
				--paper-input-container-input: {
                    color: black;
                }
				--paper-input-container: {
                    color: black;
                }
                
			}
            oe-combo, oe-input, oe-textarea {
                --border-thick: 1px;
                --border-color: #F4F5F7;
                --label-color: #828282;
                --prefix-color: #828282;
                --suffix-color: #828282;
    
                --paper-input-container: {
                  font-family: SF Pro Display!important;
                  font: SF Pro Display!important;
                  padding: 0px 0!important;
                  border-width: 0px;
                  box-sizing: border-box;
                  background-color: #F4F5F7;
                  min-height: 54px;
                }

                --paper-input-container-suffix: {
                    padding: 4px 10px 0 0 !important;
                }

                --paper-input-container-label: {
                    padding: 6px 0 6px 15px;
                    font-family: SF Pro Display;
                    color: var(--label-color, #f4f5f7);
                }

                --paper-input-container-input: {
                    top: 10px;
                    left: 20px;
                    color: #101214;
                }

                --paper-input-container-label-floating: {
                    top: 28px;
                    left: 10px;
                }

                --paper-input-container-focus-color: #00839B;
    
                --paper-input-container-underline: {
                    display: none;
                    border-bottom-color: var(--divider-color);
                }
    
                --paper-font-caption: {
                    display: block;
					font-size: 12px;
                }
    
                --paper-input-container-underline-focus: {
                   position: absolute;
                   left: 0%;
                   right: 0%;
                   top: 0%;
                   bottom: 0%;
                   
                   /* TURQOISE */
                   
                   background: #00839B;
                   margin-top: 15px;
                  }
    
                --oe-label-mixin: {
                    padding-left: 15px;
                    font-size: 16px!important;
                }
            }
            .header_cif_div {
                /*width: 42%;*/
                --paper-input-container_-_min-height: 45px !important;
                --new-padding: 10px 10px 10px 0px;
                margin-top: 4px;
                --paper-input-container-label-floating_-_font-size: 15px;
                --paper-input-minheight:10px;
                --paper-input-shared-padding:1.2% 0%;
				--paper-input-container_-_padding: 0px !important;
              }
            /*MB specific Styles Start */
             paper-button.primary-button {
                 /*Primary button class*/
                 /*changes made from 1109*/
                 /* background-color: var(--primary-color-but); */
 					background-color: #003d4c;
                 font-family: var(--medium-font);
                 font-size: var(--a-button-font-size);
                 box-shadow: none;
                 /*box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 12px 0 rgba(0, 0, 0, 0.19);*/
                 /*changes made from 1109*/
                 transition: all 0.4s;
                 --paper-button: {
                     color: rgba(255,255,255,1);
                     min-width: 62px;
                     height: 36px;
                     font-size: var(--a-button-font-size);
                     letter-spacing: 0;
                     line-height: 30px;
                     text-shadow: 0 1px 2px rgba(--button-text-shadow-color);
                     padding:8px 16px;
                 }

             }
             paper-button.primary-button:hover {
             /*changes made from 1109*/
                 /* background:var(--primary-color-but);*/
 			        	background:#025a6e;
                 opacity:0.7;
             /*changes made from 1109*/
               }
            	.mbValueStyle{
            		font-family: var(--bold-font) !important;
            		font-size: var(--mb-secondaryheading-font-size, 16px);
            		letter-spacing: 0.0025em;
            	}
            	.mbInfoStyle{
            		font-size: var(--mb-secondaryheading-font-size, 14px);
            		letter-spacing: 0.0025em;
            		color: #636C75;
            	}
                 .margin-r-12 {
                     margin-right: 12px !important;
                 }
                 .margin-r-32 {
                     margin-right: 32px !important;
                 }
                   .margin-t-32 {
                       margin-top: 32px !important;
                   }
                   .margin-t-24 {
                       margin-top: 24px !important;
                   }
                   .margin-t-16 {
                       margin-top: 16px !important;
                   }
                   .margin-t-10 {
                       margin-top: 10px !important;
                   }
                   paper-button.secondary-button {
                    /*Secondary button class*/
                    /*changes made from 1109*/
                    background-color: var(--primary-light-color);
                    /* border: 1px solid var( --primary-color-but);*/
                    border: 1px solid #003d4c;
                    font-family: var(--medium-font);
                    /* border:2px solid var( --primary-color-but);*/
                            border:2px solid #003d4c;
                    box-shadow: none;
                    /*box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 12px 0 rgba(0, 0, 0, 0.19); */
                    
                    transition: all 0.4s;
                    --paper-button: {
                        /*color: var(--secondary-color-but) !important;*/
                        color: #003d4c;
                        min-width: 62px;
                        height: 36px;
                        font-size: var(--a-button-font-size);
                        letter-spacing: 0;
                        line-height: 30px;
                        text-shadow: 0 1px 2px rgba(--button-text-shadow-color);
                        padding:8px 16px;
                        margin-right:10px;
                    }
                    /*changes made from 1109*/
                  }
                   paper-button.secondary-button:hover {
                    background: var( --seconday-button-hover-color);
                    opacity:0.6;
                  }
                   paper-button.secondary-button {
                    /*Secondary button class*/
                    color: #003d4c !important;
                  }
                   paper-button.secondary-button {
                    /*Secondary button class*/
                    background-color: var(--primary-light-color);
                    /* border: 1px solid var( --primary-color-but);*/
                    border: 1px solid #003d4c;
                    font-family: var(--medium-font);
                    /* border:2px solid var( --primary-color-but);*/
                    border:2px solid #003d4c;
                    /*box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 12px 0 rgba(0, 0, 0, 0.19);*/
                    transition: all 0.4s;
                    --paper-button: {
                        /* color: var(--brand-color); */
                        color: #003d4c;
                        min-width: 140px;
                        height: 40px;
                        font-size: var(--a-button-font-size);
                        letter-spacing: 0;
                        line-height: 30px;
                        text-shadow: 0 1px 2px rgba(--button-text-shadow-color);
                    }
                }
                paper-button.secondary-button:hover {
                    background: var( --seconday-button-hover-color);
                    opacity:0.6;
                  }

         /*END MB specific Styles */

            /*Corp Appstyles Start here*/
            .optimized-tab paper-tab {
                --paper-tab: {
                    color: var(--secondary-dark-color);
                    background: var(--primary-light-color);
                    transform: scale(.94, .9);
                    transition: transform .1s ease;
                    max-width: 220px;
                    min-width: 220px;
                }
                --paper-tab-content: {
                    z-index: 1;
                }
                --paper-tab-ink: var(--brand-color);
            }
            
            .optimized-tab paper-tab {
                border: 1px solid var(--divider-color);
                --paper-tab-content-focus: {
                    color: red;
                }
            }
            
            paper-tabs.optimized-tab {
                --paper-tabs-selection-bar-color: transparent;
                font-size: unset;
                height: 116px;
                letter-spacing: 1.33px;
            }
            
            paper-tabs.optimized-tab paper-tab.iron-selected {
                background: var(--brand-color-100);
                transform: scale(1);
                transition: transform .1s ease;
                color: var(--primary-light-color);
                font-size: unset;
                margin: 0;
                border: none;
                max-width: 220px;
                min-width: 220px;

            }
            
            /*Optimized tab styling*/
            
            
            
            
            paper-progress.brand-color {
                --paper-progress-active-color: var(--brand-color);
                --paper-progress-secondary-color: var(--divider-color);
                height: 3px;
                width: 115px;
            }           
        
			.brand-background-50 {
                background-color: var(--brand-color-50);
            }
            
            .brand-background-100 {
                background-color: var(--brand-color-100);
            }
            
            .brand-background-200 {
                background-color: var(--brand-color-200);
            }
            
            
            
            /*Corp accordian styling*/            
                paper-item {
                    --paper-item: {
                        font-size: inherit;
                    }
                    --paper-item-focused-after: {
                        background: transparent;
                    }
                    --paper-item-focused-before: {
                        background: transparent;
                    }
                }

                paper-submenu, paper-menu {
                    --paper-menu: {
                        font-size: inherit;
                    }
                    --paper-menu-focused-item-after: {
                        background: transparent;
                    }
                    --paper-menu-focused-item-before: {
                        background: transparent;
                    }
                    --paper-menu-selected-item: {
                        font-weight: inherit;
                    }
                }

                .accordian-menu .menu-content {
                    position: relative;
                    z-index: 1;
                }

                .critical-item {
                    --paper-menu-selected-item: {
                        font-weight: inherit;
                        background: var(--critical-color-100);
                        color: var(--primary-light-color);
                        border: none;
                    }
                }

                .accordian-menu paper-submenu {
                    --paper-menu-selected-item: {
                        font-weight: inherit;
                        background: var(--brand-color-100);
                        color: var(--primary-light-color);
                        border: none;
                    }
                    --paper-menu-focused-item-after: {
                        background: transparent;
                    }
                }

                .accordian-icon {
                    width: 64px;
                    height: 100%;
                }

                .currency-selector-alignment {
                    --card-align-center: {
                        left: 0;
                        right: 0;
                        margin: auto;
                    }
                }
                /*Corp accordian styling*/

                .banner-background {
                    background-image: var( --dashboard_bg_texture);
                    background-size: 100% 85%;
                    background-repeat: no-repeat;
                    background-position-y: bottom;
                }
				
		.image-div-approval-landing {
			background-size: 100% 100%;
			min-height: 212px;
			background-image: var(--dashboard_bg);
		}
				
		.image-div-approval-landing[desktop] {
			min-height: 100px;
		}
				
		.image-div-payable-landing {
			background-size: 100% 100%;
			min-height: 212px;
			background-image: var(--dashboard_bg);
		}

		.image-div-payable-landing[desktop] {
			min-height: 100px;
		}
				
		.imagebackGround-payments-collections {
			padding-top: 8px;
			background-size: 100% 100%;
			background-image: var(--dashboard_bg);
		}
				
		.image-div-product-cards {
			background-size: 100% 100%;
			background-image: var(--dashboard_bg);
		}
				
		.image-div-corporate-profile-main {
                    background-size: 100% 100%;
                    background-image: var(--dashboard_bg);
                }
				
		.image-div-profile-details{
			background-size:100% 100%;
			background-image: var(--dashboard_bg);
		}

                .brand-background-100 {
                    background: var(--brand-color-100);
                }

                .brand-background-200 {
                    background: var(--brand-color-200);
                }
                /*Tab 1 menu styling*/

                .menu-tabs {
                    padding-right: 35px;
                    font-size: var(--a-font-size-m);
                    color: var(--secondary-dark-color);
                    text-transform: uppercase;
                    transition: transform .1s ease;
                }
                
                :dir(rtl) .menu-tabs {
                    padding-left: 35px;
                    font-size: var(--a-font-size-m);
                    color: var(--secondary-dark-color);
                    text-transform: uppercase;
                    transition: transform .1s ease;
                }

                .menu-tabs[selected] {
                    transition: transform .1s ease;
                    color: var(--brand-color-200);
                    font-family: var(--medium-font) !important;
                }

                :dir(rtl) .menu-tabs[selected] {
                    transform: scale(1.2) translate(-11px);
                    transition: transform .1s ease;
                    color: var(--brand-color-200);
                    font-family: var(--medium-font) !important;
                }
                /*Tab 1 menu styling*/
                /*Tab 2 menu styling*/

                paper-tabs.menu-tabs-2 paper-tab.iron-selected {
                    color: var(--primary-light-color);
                    opacity:1.0;
                    background: var(--brand-color-200);
                }
                .menu-tabs-2 paper-tab:hover{  
                    color: var(--primary-light-color);
                    opacity:0.8;
                }
                paper-tabs.menu-tabs-2 .iron-selected:hover{
                    color: var(--primary-light-color) !important;
                    opacity:0.8;                 
                }
               

                .menu-tabs-2 paper-tab {
                    --paper-tab: {
                        color: var(--secondary-light-color);
                        font-size: var(--a-font-size-m);
                        /*color:var(--primary-light-color);*/
                        margin-left:0px;
                        font-family: var(--medium-font);
                    }
                }
                
                /* below class styling for secondary tabs eg: counterparties, payments tabs in pending approval*/
                
                .tabs-brand-background-100 {
                    background: var(--brand-color-80);
                }
            
                paper-tabs.menu-tabs-2 {
                    --paper-tabs-selection-bar-color: var(--brand-color-100);
                    font-size: inherit;
                    height:36px;
                    
                }
                /*Tab 2 menu styling*/

            
                /*Side nav Styling*/
                .side-nav paper-menu {                                     
                    --paper-menu-background-color: var(--primary-light-color);
                    --paper-menu-selected-item:{
                        background-color:var( --background-primary);
                    };
                    --paper-menu-focused-item-after:{
                        background-color:var( --background-primary);
                    }
                }
                .side-nav paper-item {
                --paper-item:{
                        color: var(--primary-dark-color);
                        font-size: var(--a-font-size-m);
                    };
                    --paper-item-focused-before:{
                        background-color:var( --background-primary);
                    }
                }
                .side-nav .selected-div {
                            width:4px;   
                }
                .side-nav .selected-div[selected]{
                    background-color:var(--secondary-brand-color); 
                }
                /*Side nav Styling*/
            
                .primary-background-200 {
                    background: var(--primary-color-200);
                }

                .critical-background-200 {
                    background: var(--critical-color-200);
                }

                .critical-background-100 {
                    background: var(--critical-color-100);
                }

                .primary-background-100 {
                    background: var(--primary-color-100);
                }
            
                .text-secondary-brand-color {
                    color: var(--secondary-brand-color);
                }            
                
                .vertical-scroll {
                    overflow-y:auto;
                }

            .mainContainer:hover .subContainer {
                display: flex;
            }           
            
            .content-wrap {
                position: relative;
                z-index: 0;
                width: 100%;
                display: block;                    
            }
            
            .success-circle-1,
            .success-circle-2 {
                border: 3px solid var(--primary-light-color);
                border-radius: 50%;
                background: var(--success-label-color);
                width: 10px;
                height: 10px;
            }

            .success-circle-2 {
                background: var(--divider-color);
            }

            .scrollable-content{
                max-height: calc(100vh - 258px);
                overflow-x: auto;
            }

            
            /*Action Buttons hover CSS*/
            .action-button {
                display : none;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
            }
            
            .action-button-wrapper:hover {
                background: var(--brand-color-50);
            }
            
            .action-button-wrapper:hover .action-button {
                display: flex;
            }
            /*Action Buttons hover CSS*/
            
            .listWrapper {
                max-height: 40vh;
                overflow: auto;
            }

            .listing-1 .listing-items {
                min-height: 53px;
                display: inline-flex;
                width: 100%;
            }
            
            
            .dot-separator {
                font-size: var(--a-font-size-xl);
                color: var(--secondary-dark-color);
                padding: 0 8px;
                position: relative;
                bottom: 2px;
            }

            .image-div-approval-landing {
                background-size: 100% 100%;
                min-height: 212px;
                background-image: var(--dashboard_bg);
            }

            .width-panel-1-corporate-mails{
				width:20% ! important;
                min-width:15%;
                overflow: hidden;
                background-image: var( --dashboard_bg_texture);
                background-repeat: no-repeat;
                background-position: bottom;
				background-size: contain;
			}
            
            .image-div-approval-landing[desktop] {
                min-height: 100px;
            }

            .image-div-payable-landing {
                background-size: 100% 100%;
                min-height: 212px;
                background-image: var(--dashboard_bg);
            }

            .image-div-payable-landing[desktop] {
                min-height: 100px;
            }

            .imagebackGround-payment-collections {
                padding-top: 8px;
                background-size: 100% 100%;
                background-image: var(--dashboard_bg);
            }
            
            app-drop-down.mobile-group-by {
                --position-right: auto;
                --position-left: -52px;

            }
            

            /* Oe combo style */
            oe-combo {
                --paper-item: {
                    font-size: var(--a-font-size-s);
                }
            }
            /* Oe-combo ie remove close icon */
            oe-combo::-ms-clear{ display:none;}
            
            /* Oe-combo ie remove close icon */
            oe-combo::-ms-clear{ display:none;}
            
            /* Nonwhite space text wrap */
			.wrap-nospace-text{
                -ms-word-break: break-all;
                word-break: break-all;
                word-break: break-word;
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                hyphens: auto;
            }
            /* styles for corporate cash management starts here*/
            .accounts-div, .account-details {
                background-color: var(--background-secondary);
            }
            .accounts-grid {
                background-color:var(--primary-light-color);
                border-radius: 2.875px;
                margin: 4px 8px !important;
                border: 1px solid var(--border-color);
                box-shadow: 0 2px 3px var(--shadow-color);
                position:relative;
                top:15px;
            }
            .account-details-card {
                padding:0px;
            }
            .account-balance-div {
                background-color:var(--bg-light-blue);
            }
            .balance-div {
                display:flex;  
                position: relative;
                top: 8px;           
            } 
            .balance-div .font-size-m, .balance-div .primary-dark-text-m {
                font-weight:500;
                padding-left: 8px;
            } 
            .available-balance {
               position: relative;
               right: -14px;
            }
            .currency-selector-alignment {
                font-size: 1px; 
            }            
            .account-details .content-align-vertical-center {
                position:relative;
                top:7px;
            } 
            .filter-alignment {
                position:relative;
                top:6px;
            }
            /* styles for corporate cash management ends here*/
            /* changes from 1109*/
            /* Menu Title Color changed 31-03-2020*/
    				.menu-tabs[selected] {
    					color: #003d4c;
                    }
    				/* Menu Title Color changed 31-03-2020*/
				
				
    				/* Menu Tabs Color changed 31-03-2020*/
    				paper-tabs.menu-tabs-2 .iron-selected {
                        background: #025a6e;
                    }
    				/* Menu Tabs Color changed */
				
    				/* Menu Tabs Color changed 31-03-2020*/
    				 .tabs-brand-background-100 {
                        background: #003d4c;
                    }
    			/* Menu Tabs Color changed 31-03-2020*/
    			/* Menu Tabs side styling changed 31-03-2020*/
    				.side-nav .selected-div[selected]{
                        background-color:#003d4c;
                    }
    				/* Menu Tabs side styling changed 31-03-2020*/
				
    				 /*secondary-back-button with red border*/
                paper-button.secondary-back-button {
                    border: 1px solid var( --primary-color-but);
                    color: var( --primary-color-but) !important;
                    min-width: 95px;
                    height: 40px;
                    font-size: var(--a-button-font-size);
                    letter-spacing: 0;
                    line-height: 30px;
                }
    				/* Checkbox -colour */
    			  #checkbox {
    				 border-color: black !important; 
    			  }
    			  #checkbox.checked {
    				 border-color: black !important;  
    			  }
    				/* Checkbox -colour */
    				/* 03-04-2020 */
        			paper-tabs.menu-tabs-2 {
                         --paper-tabs-selection-bar-color: #00839B;
                    }
        			paper-tabs.menu-tabs-2 paper-tab.iron-selected {
                        background: #025a6e;
                    }
        			.brand-background-50 {
                        background-color: #00839B;
                    }
            
                    .brand-background-100 {
                        background-color: #00839B;
                    }
            
                    .brand-background-200 {
                        background-color: #00839B;
                    }

            /*Corp app styles end here*/
			
        </style>

    </template>`;

LoginStyles.register("login-styles");
