/* ©2018 EdgeVerve Systems Limited (a fully owned Infosys subsidiary),
Bangalore, India. All Rights Reserved.
 */

var tenantId = sessionStorage.getItem("tenantId") == undefined ? window.location.href.split("=")[1] : sessionStorage.getItem("tenantId");
if (tenantId) {
    import('../styles/' + tenantId + '/app-theme.js').then((appStyles) => {
        console.log("app theme loaded");
    }).catch((reason) => {
        console.log("app theme failed to load", reason);
    });
}




/**Required polymer elements**/
import '@polymer/polymer/lib/elements/custom-style';
import '@polymer/polymer/lib/elements/dom-bind';

/*Required styling elements */



import 'speedboat_ui/elements/app-fab';
import '@polymer/iron-flex-layout/iron-flex-layout.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';

import '../styles/login-styles.js';
// import '../styles/app-styles.js';
// import '../styles/corp-app-styles.js';
// import '../styles/swiper-min.js';

/*Required paper and iron elements */
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/iron-media-query/iron-media-query.js';
import '@polymer/iron-icon/iron-icon.js';
import '@polymer/iron-image/iron-image.js';
import '@polymer/paper-button/paper-button.js';
import '@polymer/paper-checkbox/paper-checkbox.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/paper-input/paper-input-container.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-item/paper-item-body.js';
import '@polymer/paper-toast/paper-toast.js';
import '@polymer/paper-input/paper-input-behavior.js';
import '@polymer/paper-input/paper-input-error.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-material/paper-material.js';
import '@polymer/paper-spinner/paper-spinner.js';
//import '@polymer/paper-spinner/paper-spinner-lite.js'; /* Need to remove */
import '@polymer/paper-card/paper-card.js';
import '@polymer/gold-cc-expiration-input/gold-cc-expiration-input.js';
import '@polymer/gold-cc-input/gold-cc-input.js';

/*Required external elements*/
import '@xpertsea/paper-slider/l2t-paper-slider.js';

/*Required oe elements*/
import 'oe-combo/oe-combo.js';
import 'oe-ajax/oe-ajax.js';
import 'oe-i18n-msg/oe-i18n-msg.js';
import 'oe-input/oe-input.js';
import 'oe-ui-misc/oe-vbox.js';
import 'oe-ui-misc/oe-hbox.js';
import "oe-input/oe-textarea";
import "oe-data-table/oe-data-table.js";

/*Required speedboat behaviours*/
import '../behaviors/app-common-behaviour.js';
import '../behaviors/app-menu-access-behaviour.js'
import 'speedboat_ui/behaviors/keyboard-accessibility';
import 'speedboat_ui/behaviors/currency-formator-behaviour';
import 'speedboat_ui/behaviors/app-formator-behaviour';

/*Required speedboat elements*/
import 'speedboat_ui/elements/ev-login';
import 'speedboat_ui/elements/app-combo';
import 'speedboat_ui/elements/app-dialog';
import 'speedboat_ui/elements/app-media-query';
import 'speedboat_ui/elements/app-paper-input';
import 'sb_Registration_ui/elements/reg-carousel.js';
import 'speedboat_ui/elements/sb-app-localstorage-document.js';
import 'speedboat_ui/elements/app-processing-loader';
import 'speedboat_ui/elements/app-message-handler';
import 'speedboat_ui/elements/key-management.js';
import 'speedboat_ui/elements/custom-navigation.js';
import 'speedboat_ui/elements/firebase-push-notification';
import 'speedboat_ui/elements/session-timeout';
import 'speedboat_ui/elements/session-timeout-prelogin';
import 'speedboat_ui/elements/fin-auth-otp';
import 'speedboat_ui/elements/fin-auth-transaction-pwd';
import 'speedboat_ui/elements/fin-auth-debit-card';
import 'speedboat_ui/elements/fin-auth-credit-card';
import 'speedboat_ui/elements/fin-auth-element-wrapper';
import 'speedboat_ui/elements/stepup-auth';

/*Required Ag-grid elements*/
import '../styles/app-aggrid-material-theme.js';
import '../styles/app-aggrid-styles.js';
import '../styles/app-aggrid-custom-styles.js';

import { initGCharts } from "google-chart-polymer-3/google-chart.js";
initGCharts(function () {
    /* Google charts get loaded with this function call */
});

import '../node_modules/speedboat_ui/scripts/orgchart.js';